<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
        :title="isUpdate?$t('talqah_business.orders.update_btn'):$t('talqah_business.orders.new_btn')"
        :paths="[
          {
            title:$t('nav.talqah_business.title'),
          },
          {
            title:$t('nav.talqah_business.orders.title'),
            link:'talqah-business-orders'
          },
        ]"
    />
    <section class='wameed-dashboard-page-content_body products_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'
               :key="key">
          <b-card no-body class='wameed-card'>
            <b-card-header class="align-items-center tab-title">
              <h5 class='text-med-20 text-font-main mb-0'>
                {{ $t('talqah_business.orders.form.title') }}
              </h5>
              <div class="text-main text-md-20 cursor-pointer" v-if="isUpdate"
                   @click="resetPasswordModal=true">
                {{ $t('settings.system_users.reset_password') }}
              </div>
            </b-card-header>
            <wameed-form @onSubmit='submit'>
              <div slot-scope='{ invalid }'>
                <b-card-body
                    class='position-relative justify-content-between px-5'
                >
                  <h6 class="my-3">بيانات الجهة المرسلة</h6>
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='provider'
                        label="المزود"
                        placeholder="المزود"
                        variant='disable'
                        :items="getProviders"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'
                        @input="onProviderChange"
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        v-model="branch"
                        label="الفرع"
                        placeholder="الفرع"
                        variant="disable"
                        :items="branches"
                        title="name"
                        :multiple="true"

                        class="wameed_dropdown"
                        rules="required"
                        :no_options="$t('common.no_options')"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"

                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='city_from'
                        label="من المدينة"
                        placeholder="من المدينة"
                        variant='disable'
                        :items="cities"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='city_to'
                        label="إلى المدينة"
                        placeholder="إلى المدينة"
                        variant='disable'
                        :items="cities"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                  </div>
                  <div class="divider my-4"/>
                  <h6 class="my-3">بيانات الطلب</h6>
                  <div class="d-flex flex-wrap">
                    <b-col cols='12' md='6'>
                      <Wameed-date-picker
                        v-model="form.delivery_date_time"
                        placeholder="YYYY-MM-DD"
                        :config='{"type":"string","mask":"YYYY-MM-DD HH:mm"}'
                        mode="dateTime"
                        :label="'وقت التوصيل'"
                        rules="required"
                        :minDate="new Date().toDateString()"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='deliveryMethod'
                        label="وسيلة التوصيل"
                        placeholder="وسيلة التوصيل"
                        variant='disable'
                        :items="vehicles"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <h6 class="my-3">المنتجات</h6>
                    <b-col cols='12' md='12'>
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-wrap">
                          <b-col cols='12' md='12'>
                            <div v-for="(product, index) in form.products" :key="index" class="product-fields mb-3">
                              <div class="row align-items-center">
                                <!-- Product Name Input -->
                                <div class="col-md-3">
                                  <TextInput
                                    v-model="product.name"
                                    rules="required"
                                    :placeholder="'اسم المنتج'"
                                    type="text"
                                  />
                                </div>

                                <!-- Product Quantity Input -->
                                <!--                                <div class="col-md-3">-->
                                <!--                                  <TextInput-->
                                <!--                                    v-model="product.quantity"-->
                                <!--                                    rules="required|numeric"-->
                                <!--                                    :placeholder="'الكمية'"-->
                                <!--                                    type="number"-->
                                <!--                                  />-->
                                <!--                                </div>-->
                                <div class="col-md-3">
                                  <TextInput
                                    v-model="product.unit"
                                    rules="required"
                                    :placeholder="'الوحدة'"
                                    type="text"
                                  />
                                </div>
                                <div class="col-md-3">
                                  <TextInput
                                    v-model="product.unitPrice"
                                    rules="required|numeric"
                                    :placeholder="'سعر الوحدة'"
                                    type="number"
                                  />
                                </div>

                                <div class="col-md-3">
                                  <TextInput
                                    v-model="product.quantity"
                                    rules="required|numeric"
                                    :placeholder="'الكمية'"
                                    type="number"
                                  />
                                </div>

                                <!-- Remove Product Button (Hidden for First Product) -->
                                <div class="col-md-2 text-end">
                                  <div
                                    v-if="index > 0"
                                    class="remove-icon d-flex align-items-center text-danger"
                                    @click="removeProduct(index)"
                                    role="button"
                                  >
                                    <trash-icon class="me-1" />
                                    {{ $t('btn.delete') }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Add Product Button -->
                            <div
                              @click="addProduct"
                              class="outline-link d-inline-block mt-3"
                              role="button"
                            >
                              <span class="mx-2">إضافة منتج</span>
                              <add-circle-icon color="red" class="mx-2" />
                            </div>

                          </b-col>
                        </div>
                      </div>
                    </b-col>

                    <b-col cols='12' md='4' class="mt-5">
                      <b-form-checkbox v-model="form.is_paid" name="check-button" switch>
                        مدفوع
                      </b-form-checkbox>
                    </b-col>

                    <b-col cols="12" v-if="form.is_paid">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio :aria-describedby="ariaDescribedby" v-model="paid_status" name="paid_status" value="شامل الطلب والتوصيل">شامل الطلب والتوصيل</b-form-radio>
                        <b-form-radio :aria-describedby="ariaDescribedby" v-model="paid_status" name="paid_status" value="قيمة الطلب فقط">قيمة الطلب فقط</b-form-radio>
                        <b-form-radio :aria-describedby="ariaDescribedby" v-model="paid_status" name="paid_status" value="قيمة التوصيل فقط">قيمة التوصيل فقط</b-form-radio>
                      </b-form-group>
                    </b-col>
                  </div>
                  <div class="divider my-4"/>
                  <h6 class="my-3">بيانات المستلم</h6>
                  <!-- form -->
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='4'>
                      <text-input
                          id='customer_name'
                          v-model='form.customer_name'
                          :label="$t('talqah_business.orders.form.customer_name')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <TextInput
                        v-model='form.customer_phone'
                        field-classes='w-100'
                        rules='required'
                        :label="$t('form.phone.label')"
                        :placeholder="$t('form.text.placeholder')"
                        type='number'
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <TextInput
                        v-model='form.customer_address'
                        field-classes='w-100'
                        rules='required'
                        label="عنوان العميل"
                        placeholder="عنوان العميل"
                        type='text'
                      />
                    </b-col>



                    <b-col cols='12' md='4'>
                      <div @click='showMapModal = true'>
                        <text-input
                          id='map-address'
                          v-model='latLng'
                          readonly
                          name='location'
                          :label="$t('form.address.geoLocation')"
                          :placeholder="$t('form.address.geoLocation_placeholder')"
                          append-class='append-btn append-gray'
                          is-append
                          :append-text="$t('btn.add')"
                        />
                      </div>
                    </b-col>



                    <b-col cols='12' md='12' class="z-100">
                      <text-area
                        v-model="form.customer_address_desc"
                        :label="$t('talqah_business.branches.form.description')"
                        :placeholder="$t('form.text.placeholder')"
                        rows="1"
                      />
<!--                      <wameed-image-cropper-input
                          :label="$t('users.vendor.form.profile_bg')"
                          :placeholder="$t('form.image.placeholder')"
                          :uploaded-image="form.temp_profile_bg"
                          :get-object="true"
                          :fixed="true"
                          @changed="uploadBackgroundImage"
                          :width="323"
                          :height="180"
                      />-->
<!--                      <wameed-input-file-upload-->
<!--                          fileType='images'-->
<!--                          srcName='url'-->
<!--                          field-classes='w-100 z-100'-->
<!--                          functionType='front'-->
<!--                          :value="form.temp_profile_bg&&form.temp_profile_bg.length>0?form.temp_profile_bg[0].url:''"-->
<!--                          :uploadedFiles='form.temp_profile_bg'-->
<!--                        -->
<!--                          :input-placeholder=""-->
<!--                          :input-append-text="$t('form.image.browse')"-->
<!--                          @changed='uploadBackgroundImage'-->
<!--                          @deleteImage='deleteBackgroundImage'-->
<!--                      />-->
                    </b-col>

                  </div>
                  <div class="divider my-4"/>
                  <h6 class="my-3">السعر</h6>
                  <div class="d-flex flex-wrap">
                    <b-col cols='12' md='6'>
                      <TextInput
                        v-model='form.cost'
                        field-classes='w-100'
                        rules='required'
                        :label="'سعر الطلب'"
                        :placeholder="'سعر الطلب'"
                        type='number'
                      />
                    </b-col>
                    <b-col cols='12' md='6'>
                      <TextInput
                        v-model='delivery_price'
                        field-classes='w-100'
                        :disabled="!canCalculateDeliveryPrice"
                        :label="'التوصيل'"
                        :placeholder="'التوصيل'"
                        hint="سيقوم النظام بإحتساب سعر التوصيل تلقائياً في حال لم يتم إدخال قيمة"
                        type='number'
                      />

                      <div
                        v-show="canCalculateDeliveryPrice"
                        @click="calculateDeliveryPrice"
                        class="outline-link d-inline-block"
                        role="button"
                      >
                        <span class="mx-2">حساب سعر التوصيل</span>
                      </div>
                    </b-col>
                  </div>

                  <div class="divider my-4"/>
                  <h6 class="my-3">ملاحظات</h6>
                  <div class="d-flex flex-wrap">
                    <b-col cols='12' md='12'>
                      <text-area
                        v-model="form.admin_notes"
                        :label="'ملاحظات'"
                        :placeholder="$t('form.text.placeholder')"
                        rows="1"
                      />
                    </b-col>
                  </div>
                </b-card-body>
                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-white rounded-10'
                          :title="isUpdate?$t('talqah_business.orders.update_btn'):$t('talqah_business.orders.new_btn')"
                          :disabled='invalid'
                          type='submit'
                          variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-font-secondary rounded-10 '
                          :title="$t('common.cancel')"
                          type='button'
                          variant='gray'
                          @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <map-modal
        @setLocation='setLocation'
        :visible='showMapModal'
        @close='showMapModal = false'
    />


    <warning-modal
        variant='main'
        iconName='o-sent-icon'
        :visible='showSentModal'
        @close='goBack'
        @submitAction='goBack'
        :title="$t('users.vendor.sent.title')"
        :subTitle="$t('users.vendor.sent.subTitle')"
        :btnTitle="$t('common.OK')"
    />

    <warning-modal
        variant='warning'
        iconName='o-warning-icon'
        :visible="resetPasswordModal"
        @close="resetPasswordModal = false"
        @submitAction="resetPasswordAction"
        :title="$t('settings.system_users.rest_modal.title')"
        :subTitle="$t('settings.system_users.rest_modal.desc')"
        :btnTitle="$t('btn.ok')"
    />
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea as TextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import MapModal from '@/components/mapModal';
import WarningModal from '@/components/WarningModal';
import WameedImageCropperInput from "@/components/wameed/WameedImageCropperInput.vue";
import WameedDatePicker from '@/components/wameed/WameedDatePicker.vue';
import WameedSwitch from '@/components/wameed/WameedSwitch.vue';

export default {
  components: {
    WameedSwitch,
    WameedDatePicker,
    WameedImageCropperInput,
    WarningModal,
    MapModal,
    TextInput,
    TextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      key: 1,
      showMapModal: false,
      resetPasswordModal: false,
      showSentModal: false,
      provider: null,
      branches: [],
      vehicles: [],
      products_type: null,
      paid_status: null,
      governorate: null,
      deliveryMethod: null,
      city_from: null,
      city_to: null,
      cities: [],
      branch: null,
      latLng: null,
      delivery_price: null,
      form: {
        customer_address: null,
        provider_id: null,
        ref_id: null,
        vehicle_id: null,
        customer_name: null,
        customer_phone: null,
        branches: null,
        delivery_cost: null,
        admin_notes: null,
        customer_address_desc: null,
        paid_status: null,
        to_lat: null,
        to_lng: null,
        products: [
          {name: '', quantity: ''}
        ],
        cost: null,
        delivery_date_time: new Date(),
        is_paid: false,
        from_city_id: null,
        to_city_id: null,
      }

    };
  },
  computed: {
    ...mapGetters({
      getCities: 'admin/talqahBusiness/cities/getData',
      getVehicles: 'admin/talqahBusiness/vehicles/getData',
      getProviders: 'admin/talqahBusiness/providers/getData',
      getBranches: 'admin/talqahBusiness/branches/getData',
      getDeliveryPrice: 'admin/talqahBusiness/orders/getCalculatedDeliveryPrice'
    }),

    canCalculateDeliveryPrice() {
      return this.provider &&
      this.form.to_lat &&
      this.form.to_lng &&
      this.branch &&
      this.city_from &&
      this.city_to
    },

    showOrderTime() {
      return this.form.category.id !== 3;
    },

    maxOrderTime() {
      let value = this.form.min_order_time;
      if (value) {
        return '|min_value:' + value
      }
      return '';
    },

    tempProfileImageValue() {
      if (this.form.temp_profile_image.length > 0)
        return this.form.temp_profile_image[0].url
      return '';
    },
    tempBgImageValue() {
      if (this.form.temp_profile_bg && this.form.temp_profile_bg.length > 0)
        return this.form.temp_profile_bg[0].url
      return '';
    },
    isUpdate() {
      return (this.$route.name === 'users-vendors-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {
    console.log(this.$store);
    await this.$store.dispatch('admin/talqahBusiness/providers/get');


    if (this.$route.name === 'users-vendors-update' && this.$route.params.id) {
      this.$store.dispatch('admin/users/simpleDetail', {id: this.$route.params.id, type: 'vendors'}).then(async () => {

        await this.$store.dispatch('admin/users/getVendorClassification', this.userDetail.category_id);

        this.form = this.userDetail;
        if (this.userDetail.profile_image)
          this.form.temp_profile_image = {url: this.userDetail.profile_image,name: this.userDetail.profile_image};
        if (this.userDetail.profile_bg)
          this.form.temp_profile_bg = {url: this.userDetail.profile_bg,name:this.userDetail.profile_bg};

        this.form.category = this.getVendorCategories.find((cat) => cat.id === this.userDetail.category_id);
        this.form.classifications = this.getVendorClassification.filter((cat) => this.userDetail.classifications_ids.includes(cat.id));
      });

    } else {
      await this.$store.dispatch('admin/users/unsetDetail');
    }
  },


  methods: {
    addProduct() {
      this.form.products.push({
        name: '',
        quantity: '',
        unit: '',
        unitPrice: '',
      });
    },
    removeProduct(index) {
      this.form.products.splice(index, 1);
    },
    toggleIsPaid() {
      this.form.is_paid = !this.form.is_paid;
    },
    uploadProfileImage(value) {
      this.key++;
      this.form.temp_profile_image = value;
    },
    deleteProfileImage() {
      this.key++;
      this.form.temp_profile_image = [];
    },
    uploadBackgroundImage(value) {
      this.key++;
      this.form.temp_profile_bg = value;
    },
    deleteBackgroundImage() {
      this.key++;
      this.form.temp_profile_bg = [];
    },
    setLocation(value) {
      // this.form.address = value.address;
      this.latLng = value.location;
      let [lat, lng] = this.latLng.split(',');
      this.form.to_lat = lat;
      this.form.to_lng = lng;
      this.showMapModal = false;
    },
    goBack() {
      this.$router.push({name: 'talqah-business-orders'});
    },

    async onProviderChange() {
      this.branch = null;
      this.branches = [];
      this.form.provider_id = this.provider.id;
      await this.$store.dispatch('admin/talqahBusiness/branches/get', this.provider.id);
      this.branches = this.getBranches.map((branch) => {
        return {id: branch.id ,name: branch.name_ar}
      });
    },
    resetPasswordAction() {
      this.$store.dispatch('admin/users/resetVendorPassword', this.$route.params.id).then((item) => {
        if (item === 1) {
          setTimeout(() => {
            this.showSentModal = true
          }, 500)
        }
      }).finally(() => {
        this.resetPasswordModal = false;
      });
    },
    submit() {

      if (this.$route.name === 'talqah-business-orders-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/talqahBusiness/orders/update', this.form);
      } else {
        if (this.form.is_paid) {
          this.form.paid_status = this.paid_status;
        }

        if (this.delivery_price) {
          this.form.delivery_cost = this.delivery_price;
        }
        this.form.vehicle_id = this.deliveryMethod.id;
        this.form.branches = this.branch.map(item => item.id);
        this.form['is_coordinate'] = !!(this.form.to_lat && this.form.to_lng);
        this.form.provider_id = this.provider.id;
        this.form.from_city_id = this.city_from.id;
        this.form.to_city_id = this.city_to.id;
        this.$store.dispatch('admin/talqahBusiness/orders/create', this.form);
      }

    },

    async calculateDeliveryPrice() {
      let data = {
        provider_id: this.provider.id,
        to_lat: this.form.to_lat,
        to_lng: this.form.to_lng,
        branches: this.branch.map(item => item.id),
        from_city_id: this.city_from.id,
        to_city_id: this.city_to.id
      }
      await this.$store.dispatch('admin/talqahBusiness/orders/calculateDeliveryPrice', data)
      this.delivery_price = this.getDeliveryPrice.delivery_fee;
    },
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch('admin/talqahBusiness/vehicles/get')
    ]);

    await this.$store.dispatch('admin/talqahBusiness/cities/get');
    this.cities = this.getCities.map((city) => ({
      id: city.id,
      name: city.name_ar
    }));

    this.vehicles = this.getVehicles.map((vehicle) => ({
      id: vehicle.id,
      name: vehicle.name_ar
    }));

    console.log(this.vehicles, this.cities);
  }
}
;
</script>

<style scoped>
.imageHolder {
  z-index: 99;
}
</style>
